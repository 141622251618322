$icon-font-path: '../fonts/';
@import url(https://fonts.googleapis.com/css?family=Oswald);

// bower:scss
@import "bower_components/bootstrap-sass/assets/stylesheets/_bootstrap.scss";
// endbower


/*******
* Colors
*******/
$font-color: #878787;
$menu: #a2c4ba;
$menu-active: #093355;
$red: #efd6d0;


.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

html {
  height: 100%;
  overflow-x: hidden;
}

/* Space out content a bit */
body {
  background: url(../images/dots.jpg) repeat;
  color: $font-color;
  font-family: 'Oswald', sans-serif;
  height: 100%;
  overflow-x: hidden;
}

.container {
  background-color: #fff;
  padding: 0 62px;
}

p {
  line-height: 2em;
  font-size: 16px;
}

p a, p a:hover {
  color: $font-color;
}

span.small {
  font-size: 14px;
}

ul {
  font-size: 16px;
}

section.content {
  padding-bottom: 80px;
}

.wide {
  letter-spacing: 0.1em;
}

.small {
  font-size: 70%;
}

/*************
* Navigation
**************/

.navbar {
  border: 0;
  margin-bottom: 0;
}

.nav > li > a {
  color: $menu;
  letter-spacing: 0.1em;
  padding: 15px 30px 20px 0;
}

.nav > li > a:hover, .nav > li > a.active {
  background: transparent;
  text-decoration: none;
  outline: none;
  color: $menu-active;
}

.nav > li > a:focus {
  text-decoration: none;
  outline: none;
  background: transparent;
}

.navbar-brand {
  float: left;
  margin: 5px auto 5px 35px ;
  display: block;
  background: url(../images/logo.svg) no-repeat top left;
  width: 140px;
  height: 100px;
}


.navbar-toggle {
  position: relative;
  float: right;
  margin-right: 15px;
  padding: 20px 10px;
  margin-top: 24px;
  margin-bottom: 8px;
  background-color: transparent;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}


.navbar-toggle .icon-bar {
  display: block;
  width: 22px;
  height: 2px;
  border-radius: 1px;
  background: $menu-active;
}

@media only screen and (max-width: 767px) {
  .navbar {
    background: #FFF;
  }
}

/*************
* Index
**************/

#fullwidth {
  min-height: 100%;
  height: 100%;
}

#wrap {
  padding: 0;
  background: #fff;
  height:100%;
}

section#index {
  padding-top: 50px;
}

section#index > p > a > img {
  margin: 0 auto;
}

/*************
* Images
**************/

.cbp-l-grid-mosaic .cbp-l-caption-title {
  color: $menu-active;
  font: 500 16px/20px "Oswald", sans-serif;
  letter-spacing: 0.1em;
}

.cbp-l-grid-mosaic .cbp-caption-activeWrap {
  background: rgba(255, 255, 255, 0.6);
}



/************
* Portfolio
************/
section.portfolio {

}

section.portfolio .images {
  margin-bottom: 100px;
}

.cbp-nav {
  text-align: center;
}

.cbp-nav-controls {
  position: relative;
  top: 0;
  right: 0;
  margin-top: 20px;
}

.cbp-nav-next, .cbp-nav-prev {
  width: 40px;
  height: 41px;
  background: transparent;
  border: 0;
  margin: 20px;
}

.cbp-nav-next:after,
.cbp-nav-prev:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  background: url("../images/arrow_r.png") no-repeat;
  height: 41px;
  width: 40px;
}

.cbp-nav-prev:after {
  background: url("../images/arrow_l.png") no-repeat;
}

ul#eltville {
  padding-left: 20px;
}

.head {
  margin: 25px 0 5px;
  letter-spacing: 0.1em;
}

/*************
* JU
**************/
section#ju .image {
  margin-bottom: 30px;
}

section#ju p {
  margin-bottom: 20px;
}

section#ju .table td {
  border: 0;
}

section#ju .dates {
  background: $red;
  padding: 20px 15px 10px 30px;
  margin-bottom: 50px;
}

section#ju table {
  font-size: 16px;
}

/*************
* Kontakt
**************/
section#kontakt .head {
  margin: 100px 0 50px 0;
}

section#kontakt {
  p {
    font-size: 14px;
  }

  h3.small {
    font-size: 18px;
  }
  h4 {
    font-size: 17px;
  }
  .imprint {
    margin: 50px 0 40px 0;
  }
}

/*************
* Footer
**************/

footer {
  background-image: linear-gradient(to right, $menu 33%, rgba(255,255,255,0) 0%);
  background-position: top;
  background-size: 6px 2px;
  background-repeat: repeat-x;
  padding: 20px 0;
}

footer p {
  margin-top: 15px;
  font-size: 16px;
  color: $menu;
  letter-spacing: 0.1em;

  small a {
    font-size: 12px;
    margin-right: 15px;
    color: $menu;
  }
}


@media (max-width: 767px) {
  .container {
    padding: 0;
  }

  footer p {
    text-align: center;
  }

  .content p {
    margin: 0 25px 10px 25px;
  }

  .navbar-nav > li > a {
    padding-left: 15px;
  }

  ul#eltville {
    margin-left: 15px;
  }

  section#index {
    padding-top: 20px;
  }

  section#kontakt .head {
    margin-top: 40px;
  }
}

@media (min-width: 768px) {
  .navbar > .container .navbar-brand, .navbar > .container-fluid .navbar-brand {
    margin-left: 5px;
  }

  .navbar-nav > li > a {
    padding-top: 30px;
  }
}


.navbar-collapse.collapse {
  background: #fff;
  margin-right: auto;
}
